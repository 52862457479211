import HeaderBotonesInicio from "./headerBotonesInicio";
import MenuMovil from "./MenuMovil";

const HeaderInicio = () => {
  // const [fix, SetFix] = useState(false);

  // function stickyNavBar() {
  //   if (window.scrollY > 0) {
  //     SetFix(true);
  //   } else {
  //     SetFix(false);
  //   }
  // }

  // window.addEventListener("scroll", stickyNavBar);
  return (
    // <div className={fix ? "HeaderInicioFixed" : "HeaderInicio"}>
    <div className="HeaderInicio">
      <img
        className="log"
        src="../Imagenes/logoPequeño.png"
        alt="logoEmpresa"
      />
      <img className="logoMovil" src="../Imagenes/logoW.png" alt="logo2"></img>
      <HeaderBotonesInicio />
      <MenuMovil />
    </div>
  );
};

export default HeaderInicio;
