import Caja1 from "./caja1";
import Caja2 from "./caja2";
import Caja3 from "./caja3";

const WhyUs = () => {
  return (
    <div className="WhyUs">
      <div className="tituloWhyUs">Why us?</div>
      <div className="cajas">
        <Caja1 />
        <Caja2 />
        <Caja3 />
      </div>
    </div>
  );
};

export default WhyUs;
