const Blog = () => {
  return (
    <div className="Blog">
      <div className="tituloVectorBlog">
        <div className="tituloBlog">Blog</div>
        <svg
          className="vectorBlog"
          width="40"
          height="2"
          viewBox="0 0 40 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="2" rx="1" fill="#E6007E" />
        </svg>
      </div>
      <div className="tituloFechaBlog">
        <div className="DescripcionBlog">Introducing Growi.LP</div>
        <div className="fechaBlog">Aug 26, 2022</div>
      </div>
      <div className="textoImagenBlog">
        <div className="textoBlog">
          Growi.fi is a platform in the DeFi space. Our first product, Growi.LP,
          automate the Liquidity Provider process decreasing dramatically the
          impermanent loss risk - so you deposit your stablecoin in our protocol
          and we get the best opportunities for you, whilst helping the DeFi
          community proving liquidity to different exchanges in different
          blockchains.
        </div>
        <img
          className="logo2"
          src="../Imagenes/LogoGrowi2.png"
          alt="logoGrowi"
        />
      </div>
    </div>
  );
};

export default Blog;
