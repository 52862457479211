const ConnectInicio = () => {

    return (

        <div>
            <button onClick={() => {window.location.href = `https://www.app.growi.fi`}} className="ConnectInicio" disabled={true}>Launch App</button>
        </div>
)
}

export default ConnectInicio;