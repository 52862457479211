import React, { useEffect, useRef, useState } from "react";

export const CompCarousel1 = ({ props }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (!props.dragging) {
      setXPosition("0%");
    } else {
      setXPosition(`${props.drag}px`);
    }
  }, [props]);

  const [xPosition, setXPosition] = useState(
    props.lastButton === "Right"
      ? "100%"
      : props.lastButton === "Left"
      ? "-100%"
      : "0%"
  );

  return (
    <div
      className="compCarousel1 compsCarousel"
      ref={ref}
      style={{
        transform: `translateX(${xPosition})`,
      }}
    >
      <div className="textoFlexCar1">
        <div className="textoCarouselTitulo1">
          A new way to invest in crypto
        </div>

        <div className="textoCarousel1">
          the safe way to invest in DeFi, deposit your stablecoins and enjoy the
          profits
        </div>
      </div>

      <img
        className="carousel__img1 "
        src="../Imagenes/img_carousel1.png"
        alt="main block"
      />
    </div>
  );
};

export default CompCarousel1;
