/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CompCarousel1 from "./CompCarousel1";
import CompCarousel2 from "./CompCarousel2";
import CompCarousel3 from "./CompCarousel3";

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [lastButtonPressed, setLastButtonPressed] = useState("None");
  const [isDragging, setIsDragging] = useState(false);
  const [initDrag, setInitDrag] = useState(0);
  const [currentDrag, setCurrentDrag] = useState(0);
  const [dragDiff, setDragDiff] = useState(0);

  const components = [
    <CompCarousel1
      props={{
        lastButton: lastButtonPressed,
        dragging: isDragging,
        drag: dragDiff,
      }}
    />,
    <CompCarousel2
      props={{
        lastButton: lastButtonPressed,
        dragging: isDragging,
        drag: dragDiff,
      }}
    />,
    <CompCarousel3
      props={{
        lastButton: lastButtonPressed,
        dragging: isDragging,
        drag: dragDiff,
      }}
    />,
  ];

  const nextSlide = () => {
    let newIndex = (activeIndex + 1) % components.length;
    setActiveIndex(newIndex);
    setLastButtonPressed("Right");
  };

  const prevSlide = () => {
    let newIndex;
    if (activeIndex - 1 < 0) newIndex = components.length - 1;
    else newIndex = activeIndex - 1;
    setActiveIndex(newIndex);
    setLastButtonPressed("Left");
  };

  const handleStart = (e) => {
    const clientX = e.type === "mousedown" ? e.clientX : e.touches[0].clientX;
    setInitDrag(clientX);
    setIsDragging(true);
  };

  const handleMove = (e) => {
    if (isDragging) {
      const clientX = e.type === "mousemove" ? e.clientX : e.touches[0].clientX;
      setCurrentDrag(clientX);
      setDragDiff(clientX - initDrag);
    }
  };

  const handleEnd = (e) => {
    if (dragDiff > 50) {
      prevSlide();
    } else if (dragDiff < -50) {
      nextSlide();
    }
    setIsDragging(false);
    setDragDiff(0);

    // Remove event listeners for both mouse and touch
    e.currentTarget.removeEventListener("mousedown", handleStart);
    e.currentTarget.removeEventListener("mousemove", handleMove);
    e.currentTarget.removeEventListener("mouseup", handleEnd);
    e.currentTarget.removeEventListener("touchstart", handleStart);
    e.currentTarget.removeEventListener("touchmove", handleMove);
    e.currentTarget.removeEventListener("touchend", handleEnd);
  };

  useEffect(() => {
    const carouselItems = document.getElementsByClassName("carousel_items");
    Array.from(carouselItems).forEach((element) => {
      // Add event listeners for both mouse and touch
      element.addEventListener("mousedown", handleStart);
      element.addEventListener("mousemove", handleMove);
      element.addEventListener("mouseup", handleEnd);
      element.addEventListener("touchstart", handleStart);
      element.addEventListener("touchmove", handleMove);
      element.addEventListener("touchend", handleEnd);
    });

    return () => {
      // Cleanup event listeners when the component is unmounted
      Array.from(carouselItems).forEach((element) => {
        element.removeEventListener("mousedown", handleStart);
        element.removeEventListener("mousemove", handleMove);
        element.removeEventListener("mouseup", handleEnd);
        element.removeEventListener("touchstart", handleStart);
        element.removeEventListener("touchmove", handleMove);
        element.removeEventListener("touchend", handleEnd);
      });
    };
  }, [components, currentDrag, handleStart, handleMove, handleEnd]);

  //Autoplay
  /*useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      nextSlide();
    }, 1000); //miliseconds 5000

    //Clearing the interval
    return () => clearInterval(interval);
  });*/

  const mappedComponents = components.map((component, index) => (
    <div key={index} className={`carousel__item${index} carousel_items`}>
      <div onClick={prevSlide} className="carousel__btn carousel__btn1">
        〈
      </div>

      {component}
      <div onClick={nextSlide} className="carousel__btn carousel__btn2">
        〉
      </div>
    </div>
  ));

  return <div className="carousel">{mappedComponents[activeIndex]}</div>;
};
export default Carousel;
