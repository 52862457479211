import "./Inicio.css";
import React from "react";
import BackgroundInicio from "./ComponentsInicio/backgroundInicio";
import ContentsInicio from "./ComponentsInicio/contentsInicio";
import WhyUs from "./ComponentsInicio/WhyUs";
import Transparency from "./ComponentsInicio/Transparency";
import Blog from "./ComponentsInicio/Blog";
import Carousel from "./ComponentsInicio/Carousel";
import HeaderInicio from "./ComponentsInicio/headerInicio";
import Footer from "./ComponentsInicio/footer";
import DropMenu from "./ComponentsInicio/DropMenu";

export default function Inicio() {
  return (
    <div className="Inicio">
      <div className="componentesFlex">
        <HeaderInicio />

        {/* <ContentsInicio /> */}
        <Carousel />

        <WhyUs />
        <Transparency />
        <Blog />
        <Footer />
      </div>
    </div>
  );
}
